<template>
    <footer>
      <!-- Footer content -->
    </footer>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  }
}
</script>

<style>
</style>
