<template>
  <header class="header">
    <div class="first-line">
      <div class="logo-section" @click="goToHome">
        <img
          class="company-img"
          src="@/assets/whale-logo.png"
          alt="Company Logo"
        />
        <img class="x-img" src="@/assets/x.png" alt="X Icon" />
        <img
          class="logo-img"
          src="@/assets/symbol-white-logo.png"
          alt="Symbol Logo"
        />
        <img
          class="logo-word"
          src="@/assets/word-white-logo.png"
          alt="Word Logo"
        />
      </div>
      <button class="menu-icon" @click="toggleMenu">☰</button>
      <div class="menu" v-if="isMenuVisible">
        <nav>
          <!--
          <a @click="goToHome">Home</a>
          <a href="#" @click="toggleMenu">About</a>
          <a href="#" @click="toggleMenu">Contact</a>
          <div class="divider"></div>
          -->
          <div class="version">v0.0.2</div>
          <div class="welcome">Welcome,</div>
          <div class="user-info">{{ userInfo.FirstName }} {{ userInfo.LastName }}</div>
          <div class="divider"></div>
          <a @click="signOut" class="sign-out">Sign Out</a> 
        </nav>
      </div>
    </div>
    <div class="second-line">
      <div class="slider-container">
        <button
          class="slider-button"
          :class="{ active: selected === 'study' }"
          @click="selectOption('study')"
        >
          공부하기
        </button>
        <button
          class="slider-button"
          :class="{ active: selected === 'browse' }"
          @click="selectOption('browse')"
        >
          둘러보기
        </button>
      </div>
    </div>
    <div class="third-line">
      <div
        class="button-container"
        v-for="category in categories"
        :key="category.id"
      >
        <button
          :class="{
            'custom-button': !category.Selected,
            'custom-button-selected': category.Selected,
          }"
          :disabled="category.Selected" 
          @click="handleButtonClick(category)"
        >
          {{ category.Text }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { auth } from "@/firebaseConfig";
import apiService from "@/apiService";

export default {
  props: ['categories'],
  data() {
    return {
      userInfo: null,
      errorMessage: "",
      isMenuVisible: false,
      isDarkMode: false, // Assuming default theme is light mode
      isFocusMode: false,
      selected: "browse",
    };
  },
  async mounted() {
    this.userInfo = await apiService.getUser(auth.currentUser.uid);
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newPath) {
        if (newPath === "/study") {
          this.selected = "study";
        } else if (newPath === "/") {
          this.selected = "browse";
        } else {
          // Handle other routes if necessary
          this.selected = "browse";
        }
      },
    },
  },
  methods: {
    async goToHome() {
      this.isMenuVisible = false;

      var user = await apiService.getUser(auth.currentUser.uid);
      if(user) {
        if(user.LastVisitedView === "browse") {
          this.$router.push('/');
        } else {
          this.$router.push('/study');
        }
      } else {
        this.$router.push('/study');
      }
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
    },
    async selectOption(option) {
      this.selected = option;
      await this.updateLastVisitedView(option);
      this.$router.push(option === "browse" ? "/" : "/study");
    },
    async signOut() {
      this.errorMessage = ""; // Clear any previous error messages
      try {
        await auth.signOut();
        console.log("Signed out successfully!");
        this.$router.push("/login"); // Redirect to the login page
      } catch (error) {
        this.errorMessage = error.message;
        console.error("Sign out error:", error);
        this.isMenuVisible = false;
      }
    },
    async updateLastVisitedView(lastVisitedView) {
      try {
        await apiService.updateLastVisitedView(lastVisitedView);
      } catch (error) {
        console.error("Error updating LastVisitedView:", error);
      }
    },
    handleButtonClick(selectedCategory) {
      const updatedCategories = this.categories.map(category => ({
        ...category,
        Selected: category.id === selectedCategory.id,
      }));
      this.$emit('update-categories', updatedCategories);
    },
  },
};
</script>

<style scoped>
.header {
  width: 100% - 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #131417;
  color: white;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.first-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.second-line {
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
}

.third-line {
  width: 100%;
  display: flex;
  gap: 10px;
  padding-bottom: 15px;
  overflow-x: auto;
  flex-wrap: nowrap; /* Prevents the items from wrapping */
  white-space: nowrap; /* Keeps elements in a single line */
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-img,
.logo-word {
  height: 18px;
  padding: 10px 8px;
}

.x-img {
  height: 15px;
  padding: 10px;
}

.company-img {
  height: 20px;
}

.menu-icon {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #131417;
  color: white;
  padding: 20px;
  border-radius: 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

nav a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px 0;
  transition: color 0.3s;
}

nav a:hover {
  color: #ccc;
}

.divider {
  margin-top: 15px;
  margin-bottom: 20px;
  border-top: 2px solid #ccc;
}

.welcome {
  margin-left: 3px;
  font-weight: bold;
}

.user-info {
  margin-top: 5px;
  margin-left: 20px;
}

.version {
  text-align: right;
  font-size: 10px;
  padding-bottom: 12px;
}

.sign-out {
  color: black;
  background-color: orange;
  border-radius: 18px;
  text-align: center;
}

.slider-container {
  display: flex;
  border: none;
  border-radius: 25px;
  overflow: hidden;
  background-color: #292e3b;
}

.slider-button {
  background-color: #292e3b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
}

.slider-button.active {
  background-color: #131417;
  border: 1px solid #d1d1d1;
}

.button-container {
  display: block;
  justify-content: left;
  gap: 10px;
}

.custom-button {
  background-color: #292e3b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.custom-button-selected {
  background-color: #ffffff;
  color: black;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
