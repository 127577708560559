<template>
  <!-- Only display headers and footers when not on the login page -->
  <HeaderMobile
    v-if="$route.name === 'Browse' || $route.name === 'Study'"
    :categories="categories"
    @update-categories="updateCategories"
  />
  <HeaderLessonMobile
    v-if="
      $route.name === 'Lesson1' ||
      $route.name === 'Lesson2' ||
      $route.name === 'Lesson3'
    "
  />

  <router-view
    :categoryId="categoryId"
    :subcategories="subcategories"
  />

  <FooterMobile v-if="$route.name !== 'Login' && $route.name !== 'Lesson1'" />
</template>

<script>
// Importing mobile-specific components
import HeaderMobile from "@/components/mobile/HeaderMobile.vue";
import HeaderLessonMobile from "@/components/mobile/HeaderLessonMobile.vue";
import FooterMobile from "@/components/mobile/FooterMobile.vue";
import { auth } from "@/firebaseConfig";
import apiService from "@/apiService";

export default {
  components: {
    HeaderMobile,
    HeaderLessonMobile,
    FooterMobile,
  },
  data() {
    return {
      previousPath: null,
      isDarkMode: true, // Initial state of theme mode
      categoryId: "",
      categories: [],
      subcategories: [],
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      async handler() {
        if (auth.currentUser && ((this.previousPath != "/study" && this.previousPath != "/") || !this.categoryId)) {
          await this.fetchCategories();
          await this.fetchSubCategories(this.categoryId);
        } 
      },
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.previousPath = from.path;
    next();
  },
  methods: {
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode; // Toggle the theme mode
    },
    async fetchCategories() {
      try {
        const response = await apiService.getCategories();
        if (response) {
          this.categories = response
            .sort((a, b) => a.SortOrder - b.SortOrder)
            .map((category, index) => ({
              ...category,
              Selected: index === 0, // Select the first item in the sorted list
            }));

          this.categoryId = this.categories.find(
            (category) => category.Selected
          ).id;
        } else {
          console.error("No data received from API");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async updateCategories(categories) {
      this.categories = categories;
      this.categoryId = this.categories.find(
        (category) => category.Selected
      ).id;

      await this.fetchSubCategories(this.categoryId);
    },
    async fetchSubCategories(categoryId) {
      try {
        const response = await apiService.getSubCategories(categoryId);
        if (response) {
          this.subcategories = response.sort(
            (a, b) => a.SortOrder - b.SortOrder
          );
        } else {
          console.error("No data received from API");
        }
      } catch (error) {
        console.error("Error fetching Subcategories:", error);
      }
    },
  },
  async mounted() {
    this.previousPath = this.$route.path;
  },
};
</script>

<style>
html,
body {
  margin: 0px;
  overflow: hidden; /* Prevents scrolling */
  font-family: "Noto Sans KR", sans-serif; /* Using Noto Sans KR as the base font */
  background-color: #131417;
  color: #fff; /* Light text for dark mode */
}
</style>
