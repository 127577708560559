<template>
  <header class="header">
    <div class="first-line">
      <img class="back-button" src="@/assets/back-button.png" @click="handleBackClick">
      <div class="spacer"></div>
      <span class="title">표현 학습하기</span>
      <div class="spacer"></div>
      <img class="x-button" src="@/assets/x.png" @click="handleXClick">
    </div>
  </header>
</template>

<script>
import { auth } from "@/firebaseConfig";
import apiService from '@/apiService';

export default {
  data() {
    return {
      user: null
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.user = await apiService.getUser(auth.currentUser.uid);
    },
    handleBackClick() {
      if(this.$route.name === "Lesson1") {
        if(this.user) {
          if(this.user.LastVisitedView === "browse") {
            this.$router.push('/');
          } else {
            this.$router.push('/study');
          }
        } else {
          this.$router.push('/study');
        }
      } else if(this.$route.name === "Lesson2") {
        this.$router.push(`/${this.$route.params.id}/Lesson1`);
      } else if(this.$route.name === "Lesson3") {
        this.$router.push(`/${this.$route.params.id}/Lesson2`);
      }
    },
    handleXClick() {
      if(this.user) {
        if(this.user.LastVisitedView === "browse") {
          this.$router.push('/');
        } else {
          this.$router.push('/study');
        }
      } else {
        this.$router.push('/study');
      }
    }
  }
}
</script>

<style scoped>
.header {
  width: 100% - 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #131417;
  color: white;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.first-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.spacer {
  flex-grow: 1;
}

.back-button {
  height: 16px;
}

.x-button {
  height: 16px;
}
</style>
