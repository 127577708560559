// src/apiService.js
import { serverTimestamp } from 'firebase/firestore';
import { db, auth } from '@/firebaseConfig';

// Centralized error handling function
const handleError = (error) => {
  console.error('Firestore query error:', error);
  throw new Error(error.message);
};

export default {
  async getUser(uid) {
    try {
      const userDoc = await db.collection('ActiveUsers').doc(uid).get();
      return userDoc.exists ? userDoc.data() : null;
    } catch (error) {
      handleError(error);
    }
  },
  async updateLastVisitedView(lastVisitedView) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      await db.collection('ActiveUsers').doc(currentUser.uid).update({"LastVisitedView": lastVisitedView });
    } catch (error) {
      handleError(error);
    }
  },
  async getCategories() {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const categoriesSnapshot = await db.collection('Categories').get();
      return categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      handleError(error);
    }
  },
  async getCategory(categoryId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const categoryDoc = await db.collection('Categories').doc(categoryId).get();
      return categoryDoc.exists ? categoryDoc.data() : null;
    } catch (error) {
      handleError(error);
    }
  },
  async getSubCategories(categoryId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const subCategoriesSnapshot = await db.collection('SubCategories').get();
      return subCategoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.CategoryId === categoryId);
    } catch (error) {
      handleError(error);
    }
  },
  async getSubCategory(subCategoryId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const subCategoryDoc = await db.collection('Subcategories').doc(subCategoryId).get();
      return subCategoryDoc.exists ? subCategoryDoc.data() : null;
    } catch (error) {
      handleError(error);
    }
  },
  async getPhrases(subCategoryId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const phrasesSnapshot = await db.collection('Phrases').get();
      return phrasesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.SubCategoryId === subCategoryId);
    } catch (error) {
      handleError(error);
    }
  },
  async getPhrase(phraseId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const phraseDoc = await db.collection('Phrases').doc(phraseId).get();
      return phraseDoc.exists ? { id: phraseDoc.id, ...phraseDoc.data() } : null;
    } catch (error) {
      handleError(error);
    }
  },
  async getRelatedExpressions(phraseId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const relatedExpressionsSnapshot = await db.collection('RelatedExpressions').get();
      return relatedExpressionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.PhraseId === phraseId);
    } catch (error) {
      handleError(error);
    }
  },
  async getConversations(phraseId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const relatedExpressionsSnapshot = await db.collection('Conversations').get();
      return relatedExpressionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.PhraseId === phraseId).sort((a, b) => a.SortOrder - b.SortOrder);
    } catch (error) {
      handleError(error);
    }
  },
  async getProgressForCategory(categoryId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const relatedExpressionsSnapshot = await db.collection('Progress').get();
      return relatedExpressionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.UID === currentUser.uid && q.CategoryId === categoryId);
    } catch (error) {
      handleError(error);
    }
  },
  async getProgressForSubCategory(subCategoryId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }
      
      const relatedExpressionsSnapshot = await db.collection('Progress').get();
      return relatedExpressionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.UID === currentUser.uid && q.SubCategoryId === subCategoryId);
    } catch (error) {
      handleError(error);
    }
  },
  async getProgressForPhrase(phraseId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }

      const relatedExpressionsSnapshot = await db.collection('Progress').get();
      return relatedExpressionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(q => q.UID === currentUser.uid && q.PhraseId === phraseId);
    } catch (error) {
      handleError(error);
    }
  },
  async addProgressItem(categoryId, subCategoryId, phraseId) {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in');
      }

      const newItem = {
        UID: currentUser.uid,
        CategoryId: categoryId,
        SubCategoryId: subCategoryId,
        PhraseId: phraseId,
        CreatedAt: serverTimestamp(), // Add timestamp if needed
      };

      const docRef = await db.collection('Progress').add(newItem);
      return docRef.id; // Return the generated document ID
    } catch (error) {
      handleError(error);
    }
  }
};
