import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/firebaseConfig';

// Setup routes
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login-mobile" */ '@/views/mobile/LoginMobilePage.vue') 
  },
  {
    path: '/',
    name: 'Browse',
    component: () => import(/* webpackChunkName: "browse-mobile" */ '@/views/mobile/BrowseMobilePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/Study',
    name: 'Study',
    component: () => import(/* webpackChunkName: "study-mobile" */ '@/views/mobile/StudyMobilePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:id/Lesson1',
    name: 'Lesson1',
    component: () => import(/* webpackChunkName: "lesson1-mobile" */ '@/views/mobile/Lesson1MobilePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:id/Lesson2',
    name: 'Lesson2',
    component: () => import(/* webpackChunkName: "lesson2-mobile" */ '@/views/mobile/Lesson2MobilePage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:id/Lesson3',
    name: 'Lesson3',
    component: () => import(/* webpackChunkName: "lesson3-mobile" */ '@/views/mobile/Lesson3MobilePage.vue'),
    meta: { requiresAuth: true }
  },
  // Add other routes here
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
